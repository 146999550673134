import React, {Component} from "react";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Modal,
    ModalBody,
    Row
} from "reactstrap";
import EditVehicule from "../../components/Form/EditVehicule/EditVehicule";
import VA from "../../components/VA/VA";
import "./Feed.css";
import ReactTable from "react-table-v6";

const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
};

class Feed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            vr: [],
            isEditing: false,
            EditVr: null,
            update: 0,
            modal: false,
            refresh: null,
        };
    }

    componentDidMount() {
        let refresh_interval = setInterval(this.refresh, 300000);
        this.setState({refresh: refresh_interval});
        fetch(
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/vr/",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error("Failed to fetch vr status.");
                }
                return res.json();
            })
            .then((resData) => {
                this.setState({
                    vr: resData.vr.map((voiture) => { return { ...voiture };})
                    })
                })
            .catch((err) => console.log(err));
        fetch(
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/va/",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error("Failed to fetch vr status.");
                }
                return res.json();
            })
            .then((resData) => {
                resData.va.map((voiture) => {
                    if (Date.parse(voiture.deliver) < Date.now()) {
                        this.addVr2(voiture);
                        this.dh(voiture._id);
                    }
                    return voiture;
                });
            })
            .catch((err) => console.log(err));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.update !== this.state.update) {
            fetch(
                "https://asicar.herokuapp.com/voitures/" +
                localStorage.getItem("userId") +
                "/vr/",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
                .then((res) => {
                    if (res.status !== 200) {
                        throw new Error("Failed to fetch vr status.");
                    }
                    return res.json();
                })
                .then((resData) => {
                    this.setState({
                        vr: resData.vr.map((voiture) => { return { ...voiture };
                        })
                    });
                })
                .catch((err) => console.log(err));
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.refresh);
    }

    sortVR = () => {
        const sorting = this.state.vr.sort(function (a, b) {
            return new Date(a.arrival) - new Date(b.arrival);
        });
        this.setState({vr: sorting});
    };

    sortVRDeliver = () => {
        const sorting = this.state.vr.sort(function (a, b) {
            return new Date(a.deliver) - new Date(b.deliver);
        });
        this.setState({vr: sorting});
    };

    refresh = () => {
        fetch(
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/vr/",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error("Failed to fetch vr status.");
                }
                return res.json();
            })
            .then((resData) => {
                this.setState({
                    vr: resData.vr.map((voiture) => { return { ...voiture };
                    })
                });
            })
            .catch((err) => console.log(err));
    };
    dh = (id) => {
        const lien =
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/va/" +
            id;
        fetch(lien, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error("Failed to delete vr.");
                }
                this.setState({update: !this.state.update});
                return res.json();
            })
            .catch((err) => console.log(err));
    };
    addVr2 = (data) => {
        console.log(data);
        const lien =
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/vr/";
        fetch(lien, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                lastname: data.lastname,
                firstname: data.firstname,
                societe: data.societe,
                phone: data.phone,
                modele: data.modele,
                immatriculation: data.immatriculation,
                arrival: data.arrival,
                deliver: data.deliver,
                commentaires: data.commentaires,
                member: data.member,
                en_cours: 0,
                user_id: localStorage.getItem("userId"),
            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((resData) => {
                this.setState({update: !this.state.update});
                console.log(resData);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    work = (id, data) => {
        fetch(
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/vr/" +
            id,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    lastname: data.lastname,
                    firstname: data.firstname,
                    societe: data.societe,
                    phone: data.phone,
                    modele: data.modele,
                    immatriculation: data.immatriculation,
                    arrival: data.arrival,
                    deliver: data.deliver,
                    member: data.member,
                    commentaires: data.commentaires,
                    UserID: localStorage.getItem("userId"),
                    en_cours: !data.en_cours & 1,
                }),
            }
        )
            .then((res) => {
                return res.json();
            })
            .then((resData) => {
                this.setState({update: !this.state.update});
                console.log(resData);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    deleteHandler = (id) => {
        const lien =
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/vr/" +
            id;
        fetch(lien, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error("Failed to delete vr.");
                }
                this.setState({update: !this.state.update});
                return res.json();
            })
            .catch((err) => console.log(err));
    };

    editHandler = (data) => {
        console.log(Date.parse(data.arrival) < Date.now());
        this.setState({isEditing: true, EditVr: data, modal: !this.state.modal});
    };
    addVr = (e, data) => {
        e.preventDefault();
        console.log(data);
        let v_util = "vr/";
        const lien =
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/" +
            v_util;
        fetch(lien, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                lastname: data[0],
                firstname: data[1],
                societe: data[2],
                phone: data[3],
                modele: data[4],
                immatriculation: data[5],
                arrival: data[6],
                deliver: data[7],
                commentaires: data[8],
                member: data[9],
                en_cours: 0,
                user_id: localStorage.getItem("userId"),
            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((resData) => {
                this.setState({update: !this.state.update});
                console.log(resData);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    editVr = (e, data) => {
        e.preventDefault();
        console.log(data);
        const lien =
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/vr/" +
            data[10];
        fetch(lien, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                lastname: data[0],
                firstname: data[1],
                societe: data[2],
                phone: data[3],
                modele: data[4],
                immatriculation: data[5],
                arrival: data[6],
                deliver: data[7],
                commentaires: data[8],
                en_cours: data[9],
                member: data[11],
            }),
        })
            .then((res) => {
                this.setState({isEditing: false, modal: !this.state.modal});
                return res.json();
            })
            .then((resData) => {
                this.setState({update: !this.state.update});
                console.log(resData);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    deliv = (id, data) => {
        const lien =
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/pl/";
        fetch(lien, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                lastname: data.lastname,
                firstname: data.firstname,
                societe: data.societe,
                phone: data.phone,
                modele: data.modele,
                immatriculation: data.immatriculation,
                arrival: data.arrival,
                deliver: data.deliver,
                commentaires: data.commentaires,
                member: data.member,
                invite_id: data.invite_id,
                en_cours: 1,
            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((resData) => {
                this.setState({update: !this.state.update});
                this.deleteHandler(id);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    toogleModal = () => {
        this.setState({modal: !this.state.modal});
    };
    cancelHandler = () => {
        this.setState({modal: !this.state.modal});
    };

    render() {
        let form = null;
        if (this.state.isEditing && this.state.modal) {
            form = (
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toogleModal}
                    modalClassName="modal-black"
                >
                    <ModalBody>
                        <EditVehicule
                            onEdit={this.editVr}
                            onCancel={this.cancelHandler}
                            key={this.state.EditVr.id}
                            firstname={this.state.EditVr.firstname}
                            lastname={this.state.EditVr.lastname}
                            societe={this.state.EditVr.societe}
                            phone={this.state.EditVr.phone}
                            modele={this.state.EditVr.modele}
                            member={this.state.EditVr.member}
                            immatriculation={this.state.EditVr.immatriculation}
                            id={this.state.EditVr.id}
                            arrival={this.state.EditVr.arrival}
                            deliver={this.state.EditVr.deliver}
                            commentaires={this.state.EditVr.commentaires}
                            en_cours={this.state.EditVr.en_cours}
                        />
                    </ModalBody>
                </Modal>
            );
        }
        return (
            <>
                <div className="feed ml-2">
                    {this.state.vr.map((voiture) => (
                        <VA
                            key={voiture._id}
                            firstname={voiture.firstname}
                            voit={
                                voiture.invite_id
                                    ? voiture.firstname + " " + voiture.lastname
                                    : "Admin"
                            }
                            lastname={voiture.lastname}
                            societe={voiture.societe}
                            phone={voiture.phone}
                            modele={voiture.modele}
                            immatriculation={voiture.immatriculation}
                            id={voiture._id}
                            member={voiture.member}
                            invite_id={voiture.invite_id}
                            arrival={voiture.arrival}
                            deliver={voiture.deliver}
                            commentaires={voiture.commentaires}
                            en_cours={voiture.en_cours}
                            supp={this.deleteHandler}
                            enc={this.work}
                            edit={this.editHandler}
                            deliv_clik={this.deliv}
                            pl={0}
                        />
                    ))}
                    {form}
                    <div className="add_button">
                        <Row>
                            <Button
                                color="default"
                                className="btn-icon btn-round"
                                onClick={this.sortVR}
                            >
                                DA
                            </Button>
                            <Button
                                color="default"
                                className="btn-icon btn-round"
                                onClick={this.sortVRDeliver}
                            >
                                DL
                            </Button>
                        </Row>
                    </div>
                </div>
            </>
        );
    }
}

export default Feed;
