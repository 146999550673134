import React from "react";
import { Route, Switch } from "react-router-dom";
import Profil from "../pages/Profil/Profil";
import SidebarUser from "../components/Sidebar/SidebarUser";

const Guest = (props) => {
  return (
    <div>
      <SidebarUser>
        <Switch>
          <Route exact path="/utilisateurs/profil" render={() => <Profil />} />
        </Switch>
      </SidebarUser>
    </div>
  );
};

export default Guest;
