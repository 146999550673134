import React, { Component } from "react";
import Inscription from "./pages/Inscription";
import Login from "./pages/Login";

import { Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
import Admin from "./layout/Admin";
import SuperAdmin from "./layout/SuperAdmin";
import Guest from "./layout/Guest";
import Utilisateurs from "./layout/Utilisateurs";

import "./assets/css/black-dashboard-pro-react.css";
/*
import PayPalBtn from "./components/PayPal/PayPalBtn";
const paypalSubscribe = (data, actions) => {
  return actions.subscription.create({
    plan_id: "P-9JD63337EA119281PL7MKASA",
  });
};

const paypalOnError = (err) => {
  console.log("Error");
};

const paypalOnApprove = (data, detail) => {
  // call the backend api to store transaction details
  console.log("Payapl approved");
  console.log(data.subscriptionID);
};
*/
class App extends Component {
  state = {
    isAuth: false,
    error: false,
    role: "",
    userId: 0,
    guestId: 0,
  };

  loginHandler = (event, authData) => {
    event.preventDefault();
    this.setState({ authLoading: true, error: false });
    fetch("https://asicar.herokuapp.com/auth/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: authData[0],
        password: authData[1],
      }),
    })
      .then((res) => {
        if (res.status !== 200 && res.status !== 201) {
          this.setState({ error: true });
          throw new Error("Could not authenticate you!");
        }
        this.setState({ error: false });
        return res.json();
      })
      .then((resData) => {
        console.log(resData);
        localStorage.setItem("token", resData.token);
        localStorage.setItem("userId", resData.userId);
        this.setState({ role: resData.role });
        if (resData.guestId) {
          localStorage.setItem("guestId", resData.guestId);
          this.setState({ guestId: resData.guestId });
        }
        const remainingMilliseconds = 60 * 60 * 1000;
        const expiryDate = new Date(
          new Date().getTime() + remainingMilliseconds
        );
        localStorage.setItem("expiryDate", expiryDate.toISOString());
        //this.setAutoLogout(remainingMilliseconds);
        this.setState({
          error: false,
          isAuth: true,
          userId: resData.userId,
          guestId: resData.admin_id,
        });
        console.log(this.state);
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          isAuth: false,
          error: true,
        });
      });
  };
  signupHandler = (e, AuthData) => {
    console.log(AuthData);
    e.preventDefault();
    this.setState({ error: false });
    fetch("https://asicar.herokuapp.com/auth/inscription", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: AuthData[0],
        password: AuthData[1],
        lastname: AuthData[2],
        firstname: AuthData[3],
        societe: AuthData[4],
        phone: AuthData[5],
      }),
    })
      .then((res) => {
        this.setState({ error: false });
        return res.json();
      })
      .then((resData) => {
        this.loginHandler(e, [AuthData[0], AuthData[1]]);
        console.log(resData);
      })
      .catch((err) => {
        this.setState({ error: true });
        console.log(err);
      });
  };

  logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("expiryDate");
    localStorage.removeItem("userId");
    localStorage.removeItem("guestId");
  };
  /*
  setAutoLogout = (milliseconds) => {
    setTimeout(() => {
      this.logoutHandler();
    }, milliseconds);
  };*/

  //<button role="link" onClick={this.handleClick}>Checkout</button>
  /*        <PayPalBtn
          amount="50"
          currency="EUR"
          createSubscription={paypalSubscribe}
          onApprove={paypalOnApprove}
          catchError={paypalOnError}
          onError={paypalOnError}
          onCancel={paypalOnError}
        />*/

  render() {
    return (
      <div>
        {this.state.error
          ? alert("Votre mot de passe ou votre email n'est pas reconnu")
          : null}
        {this.state.isAuth && this.state.role === "Utilisateurs" ? (
          <Redirect to="/utilisateurs/profil" />
        ) : null}
        {this.state.isAuth && this.state.role === "Guest" ? (
          <Redirect to="/guest/profil" />
        ) : null}
        {this.state.isAuth && this.state.role === "Admin" ? (
          <Redirect to="/admin/task" />
        ) : null}
        {this.state.isAuth && this.state.role === "SuperAdmin" ? (
          <Redirect to="/superadmin" />
        ) : null}
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Login onLogin={this.loginHandler} />}
          />
          <Route path="/guest" render={() => <Guest />} />
          <Route path="/utilisateurs" render={() => <Utilisateurs />} />
          <Route path="/superadmin" render={() => <SuperAdmin />} />
          <Route path="/admin" render={() => <Admin />} />
          <Route
            exact
            path="/inscription"
            render={() => <Inscription onSignup={this.signupHandler} />}
          />
        </Switch>
      </div>
    );
  }
}

export default App;
