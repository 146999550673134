import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "./Sidebar.css";
import {
  Navbar,
  Container,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
} from "reactstrap";

const SidebarGuest = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("PR");
  const toogle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <Navbar expand="lg" color="info" className="sticky-top">
        <Container>
          <NavbarBrand
            style={{ color: "white" }}
            onClick={(e) => e.preventDefault()}
          >
            CARS13
          </NavbarBrand>
          <NavbarToggler onClick={toogle}>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink
                  to="/guest/profil"
                  className={activeLink === "PR" ? "act" : ""}
                  onClick={() => setActiveLink("PR")}
                >
                  Profil
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/guest/rdv"
                  className={activeLink === "RDV" ? "act" : ""}
                  onClick={() => setActiveLink("RDV")}
                >
                  Rendez-Vous
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/guest/livraison"
                  className={activeLink === "PL" ? "act" : ""}
                  onClick={() => setActiveLink("PL")}
                >
                  En attente de livraison
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      {props.children}
    </div>
  );
};

export default SidebarGuest;
