import React, { Component } from "react";
import {
  Input,
  Card,
  CardBody,
  FormGroup,
  Button,
  CardHeader,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import MyDocument from "../../components/GenPdf/GenPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import "./Archives.css";
import ReactTable from "react-table-v6";
const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};

class Archives extends Component {
  constructor(props) {
    super(props);
    let d = new Date();
    let date = d.getFullYear() + "-" + (d.getMonth() + 1);
    this.state = {
      AR: [],
      update: 0,
      today_month: date,
      refresh: null,
      nbCars: 0
    };
  }

  componentDidMount() {
    let refresh_interval = setInterval(this.refresh, 300000);
    this.setState({ refresh: refresh_interval });
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/archives/" +
        this.state.today_month,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch Archives status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          nbCars: resData.ar.length,
          AR: resData.ar.map((ar) => {
            return {
              ...{
                firstname: ar.firstname,
                lastname: ar.lastname,
                societe: ar.societe,
                phone: ar.phone,
                modele: ar.modele,
                immatriculation: ar.immatriculation,
                arrival: new Date(ar.arrival).toLocaleDateString(
                  "fr-FR",
                  options
                ),
                deliver: new Date(ar.deliver).toLocaleDateString(
                  "fr-FR",
                  options
                ),
                commentaires: ar.commentaires,
                actions: (
                  <div className="actions-right">
                    <Button
                      size="sm"
                      color="default"
                      className="animation-on-hover btn-default"
                    >
                      <PDFDownloadLink
                        document={<MyDocument ar={ar} />}
                        fileName="archives.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            "Loading document..."
                          ) : (
                            <i class="fas fa-print"></i>
                          )
                        }
                      </PDFDownloadLink>
                    </Button>
                  </div>
                ),
              },
            };
          }),
        });
      })
      .catch((err) => console.log(err));
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextState.today_month !== this.state.today_month) {
      fetch(
        "https://asicar.herokuapp.com/voitures/" +
          localStorage.getItem("userId") +
          "/archives/" +
          this.state.today_month,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.status !== 200) {
            throw new Error("Failed to fetch Archives status.");
          }
          return res.json();
        })
        .then((resData) => {
          this.setState({
            nbCars: resData.ar.length,
            AR: resData.ar.map((ar) => {
              return {
                ...{
                  firstname: ar.firstname,
                  lastname: ar.lastname,
                  societe: ar.societe,
                  phone: ar.phone,
                  modele: ar.modele,
                  immatriculation: ar.immatriculation,
                  arrival: new Date(ar.arrival).toLocaleDateString(
                    "fr-FR",
                    options
                  ),
                  deliver: new Date(ar.deliver).toLocaleDateString(
                    "fr-FR",
                    options
                  ),
                  commentaires: ar.commentaires,
                  actions: (
                    <div className="actions-right">
                      <Button
                        size="sm"
                        color="default"
                        className="animation-on-hover btn-default"
                      >
                        <PDFDownloadLink
                          document={<MyDocument ar={ar} />}
                          fileName="archives.pdf"
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              "Loading document..."
                            ) : (
                              <i class="fas fa-print"></i>
                            )
                          }
                        </PDFDownloadLink>
                      </Button>
                    </div>
                  ),
                },
              };
            }),
          });
        })
        .catch((err) => console.log(err));
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.refresh);
  }
  refresh = () => {
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/archives/" +
        this.state.today_month,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch Archives status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          nbCars: resData.ar.length,
          AR: resData.ar.map((ar) => {
            return {
              ...{
                firstname: ar.firstname,
                lastname: ar.lastname,
                societe: ar.societe,
                phone: ar.phone,
                modele: ar.modele,
                immatriculation: ar.immatriculation,
                arrival: new Date(ar.arrival).toLocaleDateString(
                  "fr-FR",
                  options
                ),
                deliver: new Date(ar.deliver).toLocaleDateString(
                  "fr-FR",
                  options
                ),
                commentaires: ar.commentaires,
                actions: (
                  <div className="actions-right">
                    <Button
                      size="sm"
                      color="default"
                      className="animation-on-hover btn-default"
                    >
                      <PDFDownloadLink
                        document={<MyDocument ar={ar} />}
                        fileName="archives.pdf"
                      >
                        {({ blob, url, loading, error }) =>
                          loading ? (
                            "Loading document..."
                          ) : (
                            <i class="fas fa-print"></i>
                          )
                        }
                      </PDFDownloadLink>
                    </Button>
                  </div>
                ),
              },
            };
          }),
        });
      })
      .catch((err) => console.log(err));
  };
  
  render() {
    return (
      <>
      <div className="task mt-3 mb-3">
        <Card style={{ width: "50rem", flexBasis: "70%", margin: "auto"  }}>
          <CardBody>
            <FormGroup>
              <h5>Choisissez votre mois</h5>
              <Input
                type="month"
                id="arrival"
                date-date-fromat="MMM yyyy"
                min="2019-01"
                max="2030-12"
                value={this.state.today_month}
                onChange={(e) => {
                  this.setState({ today_month: e.target.value });
                }}
              />
            </FormGroup>
          </CardBody>
        </Card>
        <Card className="card-stats" style={{ width: "50rem", flexBasis: "20%", margin: "auto" }}>
              <CardBody>
                <Row>
                  <Col xs="5">
                    <div className="info-icon text-center icon-success">
                    <i class="fas fa-car"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Voitures</p>
                      <CardTitle tag="h3">{this.state.nbCars}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
        </div>
        <div className="content mx-4">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Archives</CardTitle>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={this.state.AR}
                    filterable
                    columns={[
                      {
                        Header: "Prénom",
                        accessor: "firstname",
                      },
                      {
                        Header: "Nom",
                        accessor: "lastname",
                      },
                      {
                        Header: "Société",
                        accessor: "societe",
                      },
                      {
                        Header: "Modèle",
                        accessor: "modele",
                      },
                      {
                        Header: "Immatriculation",
                        accessor: "immatriculation",
                      },
                      {
                        Header: "Date d'arrivée",
                        accessor: "arrival",
                      },
                      {
                        Header: "Date de livraison",
                        accessor: "deliver",
                      },
                      {
                        Header: "Commentaires",
                        accessor: "commentaires",
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                      },
                    ]}
                    defaultPageSize={5}
                    showPaginationTop
                    
                    showPaginationBottom={false}
                    previousText='Précédent'
                    nextText='Suivant'
                    loadingText='Chargement...'
                    noDataText='Pas de données trouvées'
                    pageText='Page'
                    ofText='sur'
                    rowsText='lignes'
                  
                    // Accessibility Labels
                    pageJumpText='Sauter la page'
                    rowsSelectorText='Lignes par page'
                    /*
                      You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
                    */
                    className="-striped -highlight primary-pagination"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Archives;
