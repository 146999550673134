import React, {Component} from "react";
import {Button, Card, CardBody, CardHeader, CardTitle, Col, Row} from "reactstrap";
import VA from "../../components/VA/VA";
import "./Feed.css";
import ReactTable from "react-table-v6";
import EditVehicule from "../../components/Form/EditVehicule/EditVehicule";

const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
};

class Feed extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pl: [],
            isEditing: false,
            EditPl: null,
            update: 0,
            refresh: null,
        };
    }

    sortPLDeliver = () => {
        const sorting = this.state.pl.sort(function (a, b) {
            return new Date(a.deliver) - new Date(b.deliver);
        });
        this.setState({pl: sorting});
    };

    componentDidMount() {
        let refresh_interval = setInterval(this.refresh, 300000);
        this.setState({refresh: refresh_interval});
        fetch(
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/pl/",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error("Failed to fetch pl status.");
                }
                return res.json();
            })
            .then((resData) => {
                this.setState({
                    pl: resData.pl.map((voiture) => { return { ...voiture };})
            })})
            .catch((err) => console.log(err));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.update !== this.state.update) {
            fetch(
                "https://asicar.herokuapp.com/voitures/" +
                localStorage.getItem("userId") +
                "/pl/",
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            )
                .then((res) => {
                    if (res.status !== 200) {
                        throw new Error("Failed to fetch pl status.");
                    }
                    return res.json();
                })
                .then((resData) => {
                    this.setState({
                        pl: resData.pl.map((voiture) => { return { ...voiture };})
                    });
                })
                .catch((err) => console.log(err));
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.refresh);
    }

    refresh = () => {
        fetch(
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/pl/",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        )
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error("Failed to fetch pl status.");
                }
                return res.json();
            })
            .then((resData) => {
                this.setState({
                    pl: resData.pl.map((voiture) => { return { ...voiture };})
            })})
            .catch((err) => console.log(err));
    };
    work = (id, data) => {
        fetch(
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/pl/" +
            id,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    lastname: data.lastname,
                    firstname: data.firstname,
                    societe: data.societe,
                    phone: data.phone,
                    modele: data.modele,
                    immatriculation: data.immatriculation,
                    arrival: data.arrival,
                    deliver: data.deliver,
                    commentaires: data.commentaires,
                    member: data.member,
                    UserID: localStorage.getItem("userId"),
                    en_cours: !data.en_cours & 1,
                }),
            }
        )
            .then((res) => {
                return res.json();
            })
            .then((resData) => {
                this.setState({update: !this.state.update});
                console.log(resData);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    deleteHandler = (id) => {
        const lien =
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/pl/" +
            id;
        fetch(lien, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((res) => {
                if (res.status !== 200) {
                    throw new Error("Failed to delete pl.");
                }
                this.setState({update: !this.state.update});
                return res.json();
            })
            .catch((err) => console.log(err));
    };

    editHandler = (data) => {
        console.log(Date.parse(data.arrival) < Date.now());
        this.setState({isEditing: true, EditPl: data});
    };
    addPl = (e, data) => {
        e.preventDefault();
        console.log(data);
        const lien =
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/pl";
        fetch(lien, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                lastname: data[0],
                firstname: data[1],
                societe: data[2],
                phone: data[3],
                modele: data[4],
                immatriculation: data[5],
                arrival: data[6],
                deliver: data[7],
                commentaires: data[8],
                member: data[9],
                en_cours: 0,
            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((resData) => {
                this.setState({update: !this.state.update});
                console.log(resData);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    sortPL = () => {
        const sorting = this.state.pl.sort(function (a, b) {
            return new Date(a.arrival) - new Date(b.arrival);
        });
        this.setState({pl: sorting});
    };
    editPl = (e, data) => {
        e.preventDefault();
        console.log(data);
        const lien =
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/pl/" +
            data[10];
        fetch(lien, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                lastname: data[0],
                firstname: data[1],
                societe: data[2],
                phone: data[3],
                modele: data[4],
                immatriculation: data[5],
                arrival: data[6],
                deliver: data[7],
                commentaires: data[8],
                en_cours: data[9],
            }),
        })
            .then((res) => {
                this.setState({isEditing: false});
                return res.json();
            })
            .then((resData) => {
                this.setState({update: !this.state.update});
                console.log(resData);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    archives = (id, data) => {
        const lien =
            "https://asicar.herokuapp.com/voitures/" +
            localStorage.getItem("userId") +
            "/c_archives";
        fetch(lien, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                lastname: data.lastname,
                firstname: data.firstname,
                societe: data.societe,
                phone: data.phone,
                modele: data.modele,
                immatriculation: data.immatriculation,
                arrival: data.arrival,
                deliver: data.deliver,
                commentaires: data.commentaires,
                member: data.member,
                invite_id: data.invite_id,
                en_cours: 1,
            }),
        })
            .then((res) => {
                return res.json();
            })
            .then((resData) => {
                this.setState({update: !this.state.update});
                this.deleteHandler(id);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    render() {
        let form = null;
    if (this.state.isEditing) {
      form = (
        <EditVehicule
          onEdit={this.editPl}
          key={this.state.EditPl.id}
          firstname={this.state.EditPl.firstname}
          lastname={this.state.EditPl.lastname}
          societe={this.state.EditPl.societe}
          phone={this.state.EditPl.phone}
          modele={this.state.EditPl.modele}
          immatriculation={this.state.EditPl.immatriculation}
          id={this.state.EditPl.id}
          arrival={this.state.EditPl.arrival}
          deliver={this.state.EditPl.deliver}
          commentaires={this.state.EditPl.commentaires}
          en_cours={this.state.EditPl.en_cours}
        />
      );
    }
        return (
            <>
                <div className="content mx-2 mt-2">
                <div className="feed ml-2">
                    {this.state.pl.map((voiture) => (
                        <VA
                            key={voiture._id}
                            firstname={voiture.firstname}
                            voit={
                                voiture.invite_id
                                    ? voiture.firstname + " " + voiture.lastname
                                    : "Admin"
                            }
                            lastname={voiture.lastname}
                            societe={voiture.societe}
                            phone={voiture.phone}
                            modele={voiture.modele}
                            member={voiture.member}
                            immatriculation={voiture.immatriculation}
                            id={voiture._id}
                            arrival={voiture.arrival}
                            deliver={voiture.deliver}
                            commentaires={voiture.commentaires}
                            en_cours={voiture.en_cours}
                            supp={this.deleteHandler}
                            archiver={this.archives}
                            pl={1}
                        />
                    ))}
                    {form}
                    <div className="add_button">
                        <Row>
                            <Button
                                color="default"
                                className="btn-icon btn-round"
                                onClick={this.sortPL}
                            >
                                DA
                            </Button>
                            <Button
                                color="default"
                                className="btn-icon btn-round"
                                onClick={this.sortPLDeliver}
                            >
                                DL
                            </Button>
                        </Row>
                    </div>
                </div>
                </div>
            </>
        );
    }
}

export default Feed;
