import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import "./Sidebar.css";
import {
  Navbar,
  Container,
  NavbarBrand,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
} from "reactstrap";

const Sidebar = (props) => {
  //let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("task");
  const toogle = () => {
    setIsOpen(!isOpen);
  };
  /*
  const deco = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("expiryDate");
    localStorage.removeItem("userId");
    localStorage.removeItem("guestId");
    history.push('/')
  }*/
  /*      <div className="bg-info">
     <img src={Logo} width="10%" className="mx-auto d-block"/>
     </div>*/
  return (
    <div>
      <Navbar expand="lg" color="info" className="sticky-top">
        <Container>
          <NavbarBrand
            style={{ color: "white" }}
            onClick={(e) => e.preventDefault()}
          >
            CARS13
          </NavbarBrand>
          <NavbarToggler onClick={toogle}>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
            <span className="navbar-toggler-bar navbar-kebab"></span>
          </NavbarToggler>
          <Collapse isOpen={isOpen} navbar>
            <Nav navbar>
              <NavItem>
                <NavLink
                  to="/admin/task"
                  className={activeLink === "task" ? "act" : ""}
                  onClick={() => setActiveLink("task")}
                >
                  Tâches
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/admin/verifier"
                  className={activeLink === "RDV" ? "act" : ""}
                  onClick={() => setActiveLink("RDV")}
                >
                  RDV en attente
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/admin/va"
                  className={activeLink === "VA" ? "act" : ""}
                  onClick={() => setActiveLink("VA")}
                >
                  Mes véhicules
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/admin/vr"
                  className={activeLink === "VR" ? "act" : ""}
                  onClick={() => setActiveLink("VR")}
                >
                  Véhicules en retard
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/admin/pl"
                  className={activeLink === "PL" ? "act" : ""}
                  onClick={() => setActiveLink("PL")}
                >
                  Prêt à la livraison
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/admin/archives"
                  className={activeLink === "AR" ? "act" : ""}
                  onClick={() => setActiveLink("AR")}
                >
                  Archives
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="/admin/settings"
                  className={activeLink === "RE" ? "act" : ""}
                  onClick={() => setActiveLink("RE")}
                >
                  Réglages
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      {props.children}
    </div>
  );
};

export default Sidebar;
