import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Logo from "../svg/LogoSVG.svg";
import {
  Button,
  Input,
  CardHeader,
  Card,
  CardBody,
  FormGroup,
  CardFooter,
  Label,
} from "reactstrap";
//import LogoAs from "../svg/Logo transparent.png";
const Incription = (props) => {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [phone, setPhone] = useState("");
  const [societe, setSociete] = useState("");

  return (
    <>
      <img src={Logo} width="20%" className="mx-auto d-block" alt="logo" />
      <div className="auth mt-5">
        <Card style={{ width: "50rem" }}>
          <form
            onSubmit={(e) =>
              props.onSignup(e, [
                email,
                password,
                lastname,
                firstname,
                societe,
                phone,
              ])
            }
          >
            <CardHeader>
              <h2>Inscrivez-vous</h2>
            </CardHeader>
            <CardBody>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  required
                  id="email"
                  value={email}
                  placeholder="Email..."
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label>Mot de passe</Label>
                <Input
                  required
                  type="password"
                  placeholder="Mot de passe..."
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormGroup>

              <div className="form-row">
                <FormGroup className="col-md-6">
                  <Label>Nom de famille</Label>
                  <Input
                    required
                    placeholder="Nom de famille..."
                    id="lastname"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label>Prénom</Label>
                  <Input
                    required
                    id="firstname"
                    placeholder="Prénom..."
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                  />
                </FormGroup>
              </div>
              <div className="form-row">
                <FormGroup className="col-md-6">
                  <Label>Téléphone</Label>
                  <Input
                    required
                    id="phone"
                    placeholder="Téléphone..."
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label>Société</Label>
                  <Input
                    required
                    id="societe"
                    placeholder="Société..."
                    value={societe}
                    onChange={(e) => setSociete(e.target.value)}
                  />
                </FormGroup>
              </div>
            </CardBody>
            <CardFooter className="text-center">
              <Button color="info" type="submit">
                S'inscrire
              </Button>
            </CardFooter>
            <CardFooter>
              <Button color="black" onClick={(e) => history.push("/")}>
                Se connecter
              </Button>
              <Button
                onClick={() => {
                  setEmail("");
                  setPassword("");
                  setPhone("");
                  setSociete("");
                  setFirstname("");
                  setLastname("");
                }}
                color="danger"
                type="reset"
                className="float-right"
              >
                Annuler
              </Button>
            </CardFooter>
          </form>
        </Card>
      </div>
    </>
  );
};

export default Incription;
