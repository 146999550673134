import React, { Component } from "react";
import { Button, Modal, ModalBody } from "reactstrap";

import VehiculeFormGuest from "../../components/Form/Vehicule/VehiculeFormGuest";
import VehiculeGuest from "../../components/VA/VehiculeGuest";
import "./Feed.css";

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};


class FeedGuest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      va: [],
      user: {},
      isEditing: false,
      EditVa: null,
      modal: false,
      refresh: null,
    };
  }

  componentDidMount() {
    fetch(
      "https://asicar.herokuapp.com/voitures/" +
        localStorage.getItem("userId") +
        "/vag/",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch va status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          va: resData.va.map((voiture) => {
            return { ...voiture };
          }),
        });
      })
      .catch((err) => console.log(err));
    let refresh_interval = setInterval(this.refresh, 300000);
    this.setState({ refresh: refresh_interval });
    this.getUser();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.update !== this.state.update) {
      fetch(
        "https://asicar.herokuapp.com/voitures/" +
          localStorage.getItem("userId") +
          "/vag/",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((res) => {
          if (res.status !== 200) {
            throw new Error("Failed to fetch va status.");
          }
          return res.json();
        })
        .then((resData) => {
          this.setState({
            va: resData.va.map((voiture) => {
              return { ...voiture };
            }),
          });
        })
        .catch((err) => console.log(err));
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.refresh);
  }
  getUser = () => {
    fetch(
      "https://asicar.herokuapp.com/auth/infos/" +
        localStorage.getItem("userId"),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to fetch user status.");
        }
        return res.json();
      })
      .then((resData) => {
        this.setState({
          user: resData.user,
        });
      })
      .catch((err) => console.log(err));
  };

  addVa = (e, data) => {
    e.preventDefault();
    let v_util = "va/";
    if (Date.parse(data[7]) < Date.now()) {
      v_util = "vr/";
    }
    const lien =
      "https://asicar.herokuapp.com/voitures/" +
      localStorage.getItem("guestId") +
      "/" +
      v_util;
    fetch(lien, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lastname: data[0],
        firstname: data[1],
        societe: data[2],
        phone: data[3],
        modele: data[4],
        immatriculation: data[5],
        arrival: data[6],
        deliver: data[7],
        commentaires: data[8],
        en_cours: 0,
        invite_id: localStorage.getItem("userId"),
        verif: 0,
      }),
    })
      .then((res) => {
        return res.json();
      })
      .then((resData) => {
        this.setState({ update: !this.state.update, modal: !this.state.modal });
        console.log(resData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  toogleModal = () => {
    this.setState({ modal: !this.state.modal });
  };
  cancelHandler = () => {
    this.setState({ modal: !this.state.modal });
  };
  deleteHandler = (id) => {
    const lien =
      "https://asicar.herokuapp.com/voitures/" +
      localStorage.getItem("userId") +
      "/va/" +
      id;
    fetch(lien, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error("Failed to delete va.");
        }
        this.setState({ update: !this.state.update });
        return res.json();
      })
      .catch((err) => console.log(err));
  };
  render() {
    console.log(this.state.user.societe);
    return (
      <div className="feed ml-2">
        {this.state.va.map((voiture) => (
          <VehiculeGuest
            voit={
              voiture.invite_id
                ? voiture.firstname + " " + voiture.lastname
                : "Admin"
            }
            key={voiture._id}
            societe={voiture.societe}
            firstname={voiture.firstname}
            lastname={voiture.lastname}
            phone={voiture.phone}
            modele={voiture.modele}
            immatriculation={voiture.immatriculation}
            id={voiture._id}
            arrival={voiture.arrival}
            deliver={voiture.deliver}
            en_cours={voiture.en_cours}
            commentaires={voiture.commentaires}
            supp={this.deleteHandler}
            pl={0}
          />
        ))}
        <Modal
          isOpen={this.state.modal}
          toggle={this.toogleModal}
          modalClassName="modal-black"
        >
          <ModalBody>
            <VehiculeFormGuest
              onAdd={this.addVa}
              onCancel={this.cancelHandler}
              firstname={this.state.user.firstname}
              lastname={this.state.user.lastname}
              societe={this.state.user.societe}
              phone={this.state.user.phone}
            />
          </ModalBody>
        </Modal>
        <div className="button_rdv">
          <Button color="success" onClick={this.toogleModal}>
            Prendre un rendez-vous
          </Button>
        </div>
      </div>
    );
  }
}

export default FeedGuest;
